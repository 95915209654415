// src/components/Header.js
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { FaRocket } from "react-icons/fa";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LogoImage from "../assets/ISO_AUDIT_PRO_LOGO.png";

gsap.registerPlugin(ScrollTrigger);

const HeaderContainer = styled.header`
  width: 100%;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.scrolled ? "#fff9ff" : "transparent")};
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: background 0.3s;
  box-shadow: ${(props) =>
    props.scrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none"};
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none; /* For simplicity, omitting mobile menu implementation */
  }
`;

const NavLink = styled.a`
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: ${(props) => (props.scrolled ? "#333333" : "#000000")};
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background: #ff7f50;
    transition: width 0.3s;
    position: absolute;
    bottom: -5px;
    left: 0;
  }

  &:hover::after {
    width: 100%;
  }
`;

const HeroButton = styled.button`
  padding: 15px 20px;
  font-size: 0.8rem;
  color: #ffffff;
  background: linear-gradient(135deg, #ff416c 0%, #ff4b2b 100%);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 8px 15px rgba(255, 75, 43, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-left: 20px;
  margin-top: -10px;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 25px rgba(255, 75, 43, 0.4);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 8px 15px rgba(255, 75, 43, 0.3);
  }
`;

const Header = () => {
  const [scrolled, setScrolled] = React.useState(false);
  const headerRef = useRef(null);
  const linksRef = useRef([]);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Scroll-based background change
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 80) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Animate navigation links on mount
    gsap.fromTo(
      linksRef.current,
      { y: -50, opacity: 80 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 1,
        ease: "power2.out",
      }
    );

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderContainer scrolled={scrolled} ref={headerRef}>
      <img
        src={LogoImage}
        alt="logo"
        style={{ width: "130px", height: "30px", marginTop: "-3px" }}
        scrolled={scrolled}
      />
      <div style={{ display: "flex" }}>
        <Nav>
          {["Home", "About", "Services", "Testimonials", "Contact"].map(
            (item, index) => (
              <NavLink
                key={index}
                href={`#${item.toLowerCase()}`}
                scrolled={scrolled}
                ref={(el) => (linksRef.current[index] = el)}
              >
                {item}
              </NavLink>
            )
          )}
        </Nav>
        <HeroButton
          ref={buttonRef}
          onClick={() => {
            window.open("https://app.isoauditpro.com", "_blank");
          }}
        >
          Get Started <FaRocket />
        </HeroButton>
      </div>
    </HeaderContainer>
  );
};

export default Header;
