// src/components/Services.js
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaLaptopCode, FaChartLine, FaMobileAlt } from 'react-icons/fa';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ServicesSection = styled.section`
  padding: 100px 50px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ServicesTitle = styled.h3`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 50px;
`;

const ServicesContainer = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
`;

const ServiceCard = styled.div`
  background: #f5f6fa;
  padding: 30px;
  border-radius: 10px;
  width: 250px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const ServiceIcon = styled.div`
  font-size: 2.5rem;
  color: #ff7f50;
  margin-bottom: 20px;
`;

const ServiceTitle = styled.h4`
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #555555;
`;

const services = [
  {
    icon: <FaLaptopCode />,
    title: 'Web Development',
    description: 'Building responsive and dynamic websites tailored to your business needs.',
  },
  {
    icon: <FaChartLine />,
    title: 'Digital Marketing',
    description: 'Enhancing your online presence and driving targeted traffic to your platforms.',
  },
  {
    icon: <FaMobileAlt />,
    title: 'Mobile Apps',
    description: 'Creating user-friendly mobile applications for both Android and iOS platforms.',
  },
];

const Services = () => {
  const servicesRef = useRef(null);

  useEffect(() => {
    const elements = servicesRef.current.children;

    gsap.fromTo(
      elements,
      { autoAlpha: 0, y: 50 },
      {
        duration: 1,
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: servicesRef.current,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, []);

  return (
    <ServicesSection id='services'>
      <ServicesTitle>Our Services</ServicesTitle>
      <ServicesContainer ref={servicesRef}>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceIcon>{service.icon}</ServiceIcon>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceCard>
        ))}
      </ServicesContainer>
    </ServicesSection>
  );
};

export default Services;