// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import styled from 'styled-components';

const Root = styled.div`
  padding-top: 80px; /* Adjust based on header height */
`;

ReactDOM.render(
  <React.StrictMode>
    <Root>
      <App />
    </Root>
  </React.StrictMode>,
  document.getElementById('root')
);