// src/components/Contact.js
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ContactSection = styled.section`
  padding: 100px 50px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactTitle = styled.h3`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 50px;
`;

const ContactForm = styled.form`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  &:focus {
    border-color: #ff7f50;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
  min-height: 150px;
  &:focus {
    border-color: #ff7f50;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  border: none;
  border-radius: 50px;
  background: #ff7f50;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: #ff5722;
  }
`;

const Contact = () => {
  const contactRef = useRef(null);

  useEffect(() => {
    const element = contactRef.current;

    gsap.fromTo(
      element,
      { autoAlpha: 0, y: 100 },
      {
        duration: 1,
        autoAlpha: 1,
        y: 0,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: element,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, []);

  return (
    <ContactSection ref={contactRef} id='contact'>
      <ContactTitle>Get in Touch</ContactTitle>
      <ContactForm
        onSubmit={(e) => {
          e.preventDefault();
          // Handle form submission
          alert('Thank you for contacting us!');
        }}
      >
        <Input type="text" placeholder="Your Name" required />
        <Input type="email" placeholder="Your Email" required />
        <Input type="text" placeholder="Subject" required />
        <TextArea placeholder="Your Message" required />
        <SubmitButton type="submit">Send Message</SubmitButton>
      </ContactForm>
    </ContactSection>
  );
};

export default Contact;