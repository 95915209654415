// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 40px 50px;
  background: #333333;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 1.2rem;
  transition: color 0.3s;

  &:hover {
    color: #ff7f50;
  }
`;

const FooterText = styled.p`
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialLinks>
        <SocialIcon href="#" aria-label="Facebook">
          <FaFacebookF />
        </SocialIcon>
        <SocialIcon href="#" aria-label="Twitter">
          <FaTwitter />
        </SocialIcon>
        <SocialIcon href="#" aria-label="Instagram">
          <FaInstagram />
        </SocialIcon>
      </SocialLinks>
      <FooterText>&copy; {new Date().getFullYear()} MyBrand. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;