// src/components/Hero.js
import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Keyframes for cube animations
const rotate = keyframes`
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(360deg);
  }
`;

// Styled Components

const HeroSection = styled.section`
  height: 100vh;
  padding: 80px 50px;
  background: linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  color: #2c3e50;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  color: #273c75;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HighlightedText = styled.span`
  color: #e74c3c;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #c0392b;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.3rem;
  color: #487eb0;
  max-width: 650px;
  text-align: center;
  margin-bottom: 40px;
  font-family: "Playfair Display", serif;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 20px;
  }
`;

const AnimatedShape = styled.div`
  position: absolute;
  width: 220px;
  height: 220px;
  background: rgba(231, 76, 60, 0.2);
  border-radius: 50%;
  top: -80px;
  left: -80px;
  z-index: 1;
  animation: float 25s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translateY(0px) scale(1);
    }
    50% {
      transform: translateY(30px) scale(1.1);
    }
    100% {
      transform: translateY(0px) scale(1);
    }
  }
`;

const AnimatedShapeBottom = styled.div`
  position: absolute;
  width: 180px;
  height: 180px;
  background: rgba(52, 152, 219, 0.2);
  border-radius: 50%;
  bottom: -60px;
  right: -60px;
  z-index: 1;
  animation: float 30s ease-in-out infinite alternate;

  @keyframes float {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    100% {
      transform: translateY(-25px) rotate(360deg);
    }
  }
`;

// New Flower Shape
const Flower = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  background: url("flower.svg") no-repeat center center / contain;
  top: 20%;
  left: 70%;
  opacity: 0.7;
  animation: floatFlower 20s ease-in-out infinite;

  @keyframes floatFlower {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(20px) rotate(180deg);
    }
    100% {
      transform: translateY(0px) rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
    left: 60%;
  }
`;

// New Cube Shape
const Cube = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  background: rgba(46, 204, 113, 0.6);
  transform: rotateY(0deg) rotateX(0deg);
  top: 50%;
  left: 30%;
  z-index: 2;
  animation: ${rotate} 15s linear infinite;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    left: 25%;
  }
`;

const CubeSecondary = styled(Cube)`
  background: rgba(155, 89, 182, 0.6);
  top: 65%;
  left: 60%;
  animation-duration: 20s;

  @media (max-width: 768px) {
    left: 55%;
    top: 60%;
  }
`;

const Hero = () => {
  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const buttonRef = useRef(null);
  const shapeRef = useRef(null);
  const shapeBottomRef = useRef(null);
  const flowerRef = useRef(null);
  const cubeRef = useRef(null);
  const cubeSecondaryRef = useRef(null);

  useEffect(() => {
    const gsapContext = gsap.context(() => {
      // Split Text into spans for individual letter animations
      const letters = titleRef.current.querySelectorAll(".letter");

      // Hero Title Animation
      gsap.from(letters, {
        y: 100,
        opacity: 0,
        stagger: 0.05,
        duration: 1.2,
        ease: "expo.out",
      });

      // Hero Subtitle Animation
      gsap.from(subtitleRef.current, {
        y: 50,
        opacity: 0,
        duration: 1,
        delay: 0.5,
        ease: "power3.out",
      });

      // Hero Button Animation
      gsap.from(buttonRef.current, {
        scale: 0.8,
        opacity: 0,
        duration: 0.8,
        delay: 1,
        ease: "elastic.out(1, 0.5)",
      });

      // Flower Animation (if any additional animations are needed)
      gsap.from(flowerRef.current, {
        scale: 0.5,
        opacity: 0,
        duration: 1.5,
        delay: 0.8,
        ease: "back.out(1.7)",
      });

      // Cube Animations
      gsap.from(cubeRef.current, {
        scale: 0.5,
        opacity: 0,
        duration: 1.2,
        delay: 0.6,
        ease: "back.out(1.7)",
      });

      gsap.from(cubeSecondaryRef.current, {
        scale: 0.5,
        opacity: 0,
        duration: 1.2,
        delay: 0.7,
        ease: "back.out(1.7)",
      });

      // ScrollTrigger for Hero Section Entrance
      gsap.from(heroRef.current, {
        opacity: 0,
        y: 50,
        duration: 1,
        scrollTrigger: {
          trigger: heroRef.current,
          start: "top center+=100",
        },
      });
    }, heroRef);

    return () => {
      gsapContext.revert();
    };
  }, []);

  // Function to split text into spans for individual letter animation
  const splitText = (text) => {
    return text.split("").map((char, index) => {
      return (
        <span key={index} className="letter">
          {char === " " ? "\u00A0" : char}
        </span>
      );
    });
  };

  return (
    <HeroSection ref={heroRef} id="home">
      {/* Background Shapes */}
      <AnimatedShape ref={shapeRef} />
      <AnimatedShapeBottom ref={shapeBottomRef} />
      <Flower ref={flowerRef} />
      <Cube ref={cubeRef} />
      <CubeSecondary ref={cubeSecondaryRef} />

      {/* Content */}
      <HeroTitle ref={titleRef}>
        {splitText("Welcome to ")}
        <HighlightedText>{splitText("ISOAuditPro")}</HighlightedText>
      </HeroTitle>
      <HeroSubtitle ref={subtitleRef}>
        We provide exceptional services to help your business grow. Join us to
        start your journey towards success.
      </HeroSubtitle>
    </HeroSection>
  );
};

export default Hero;
