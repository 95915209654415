// src/components/Testimonials.js
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

const TestimonialsSection = styled.section`
  padding: 100px 50px;
  background: #f0f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TestimonialsTitle = styled.h3`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 50px;
`;

const TestimonialsContainer = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
`;

const TestimonialCard = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  width: 300px;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const QuoteIconLeft = styled(FaQuoteLeft)`
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 1.5rem;
  color: #ff7f50;
`;

const QuoteIconRight = styled(FaQuoteRight)`
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 1.5rem;
  color: #ff7f50;
`;

const TestimonialText = styled.p`
  font-size: 1rem;
  color: #555555;
  margin-top: 20px;
`;

const TestimonialAuthor = styled.h4`
  font-size: 1.1rem;
  color: #333333;
  margin-top: 15px;
`;

const testimonials = [
  {
    text: 'This company provided excellent services that significantly boosted our online presence. Highly recommended!',
    author: 'Jane Doe, CEO of Acme Corp',
  },
  {
    text: 'Their team is professional, responsive, and delivered beyond our expectations. A true partner in our success.',
    author: 'John Smith, Marketing Manager at Beta Inc.',
  },
  {
    text: 'Outstanding work on our website redesign! It not only looks great but also functions seamlessly.',
    author: 'Emily Clark, Founder of Delta LLC',
  },
];

const Testimonials = () => {
  const testimonialsRef = useRef(null);

  useEffect(() => {
    const elements = testimonialsRef.current.children;

    gsap.fromTo(
      elements,
      { autoAlpha: 0, y: 50 },
      {
        duration: 1,
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: testimonialsRef.current,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, []);

  return (
    <TestimonialsSection id='testimonials'>
      <TestimonialsTitle>What Our Clients Say</TestimonialsTitle>
      <TestimonialsContainer ref={testimonialsRef}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index}>
            <QuoteIconLeft />
            <TestimonialText>"{testimonial.text}"</TestimonialText>
            <TestimonialAuthor>{testimonial.author}</TestimonialAuthor>
            <QuoteIconRight />
          </TestimonialCard>
        ))}
      </TestimonialsContainer>
    </TestimonialsSection>
  );
};

export default Testimonials;