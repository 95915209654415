// src/components/About.js
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MissionImage from "../assets/images/mission.jpeg";
import ExpertiseImage from "../assets/images/expertise.jpeg";
import ProcessImage from "../assets/images/process.webp";

gsap.registerPlugin(ScrollTrigger);

// Styled Components

const ImageSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const AboutSection = styled.section`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
  justify-content: space-between;
  padding: 100px 50px;
  background: ${({ bg }) => bg || "#ffffff"};
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 60px 20px;
  }
`;

const AboutImageContent = styled.section`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    width: 80%;
    margin-bottom: 30px;
  }
`;

const AboutImage = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
  opacity: 0;

  @media (max-width: 992px) {
    width: 80%;
  }
`;

const AboutContent = styled.div`
  width: 50%;
  color: #333333;
  padding: 0 20px;
  opacity: 0;

  @media (max-width: 992px) {
    width: 100%;
    text-align: center;
  }
`;

const AboutTitle = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2c3e50;

  @media (max-width: 992px) {
    font-size: 2rem;
  }
`;

const AboutText = styled.p`
  font-size: 1.15rem;
  line-height: 1.8;
  color: #555555;

  @media (max-width: 992px) {
    font-size: 1rem;
  }
`;

// Data Array
const aboutData = [
  {
    id: 1,
    title: "Our Mission",
    text: `At ISO Audit Pro, our unwavering commitment is to empower accredited inspection and materials testing laboratories. We specialize in ISO assessments and quality management system consulting. Our mission is clear: to elevate your organization’s performance. Through superior service, expert guidance, and efficient solutions, we help you achieve your quality goals. Additionally, we offer targeted training for key personnel, ensuring sustainable excellence in your operations. Together, we forge a path toward quality, compliance, and continuous improvement.`,
    image: MissionImage,
    bg: "#f9f9f9",
  },
  {
    id: 2,
    title: "Our Expertise",
    text: `With over 30 years of industry experience, ISO Audit Pro stands as a beacon of excellence. Our seasoned team specializes in ISO assessments and quality management system consulting for inspection and testing laboratories. But our expertise extends beyond mere assessments and ISO standard requirements. Our team has walked the path as an accredited inspection agency and testing laboratory ourselves. This dual perspective uniquely positions us to understand the intricate needs of our clients.
    At ISO Audit Pro, we’re not just experts; we’re your trusted partners in achieving and maintaining the highest standards while propelling your success. `,
    image: ExpertiseImage, // Replace with actual vision image if available
    bg: "#ffffff",
    reverse: true,
  },
  {
    id: 3,
    title: "Our Process",
    text: `At ISO Audit Pro, our process is meticulously crafted to ensure excellence. It begins with a systematic review of a company’s internal workflows, dissecting every facet to identify areas for improvement. Our team’s deep familiarity with ISO standards allows us to align these reviews with the intent of the standards, ensuring compliance and effectiveness.
    Armed with insights, we collaborate closely with our clients. Together, we chart a course to achieve their quality management goals. Whether it’s refining processes, enhancing documentation, or optimizing procedures, we guide organizations toward sustainable excellence. Our commitment lies in fostering quality, compliance, and continuous improvement—empowering businesses to thrive in a competitive landscape. `,
    image: ProcessImage, // Replace with actual values image if available
    bg: "#f9f9f9",
  },
];

// Main Component

const About = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    sectionsRef.current.forEach((section, index) => {
      const image = section.querySelector("img");
      const content = section.querySelector("div");

      gsap.fromTo(
        image,
        { autoAlpha: 0, x: index % 2 === 0 ? -100 : 100 },
        {
          duration: 1,
          autoAlpha: 1,
          x: 0,
          ease: "power2.out",
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );

      gsap.fromTo(
        content,
        { autoAlpha: 0, y: 50 },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: "power2.out",
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  return (
    <ImageSection id="about">
      {aboutData.map((item, index) => (
        <AboutSection
          key={item.id}
          bg={item.bg}
          reverse={item.reverse}
          ref={(el) => (sectionsRef.current[index] = el)}
        >
          <AboutImageContent>
            <AboutImage src={item.image} alt={item.title} />
          </AboutImageContent>
          <AboutContent>
            <AboutTitle>{item.title}</AboutTitle>
            <AboutText>{item.text}</AboutText>
          </AboutContent>
        </AboutSection>
      ))}
    </ImageSection>
  );
};

export default About;
